<template>
   <div class="ps-breadcrumb">
        <div class="ps-container">
            <div class="d-sm-flex justify-content-between mb-1 mt-1">
                <div class="pull-left">
                  <ul class="breadcrumb">
                      <li><router-link to="/">Home</router-link></li>
                      <li>{{name}}</li>
                  </ul>
                </div>
                <div class="pull-right" v-if="isPharmacy">
                  <router-link :to="{name: 'create_prescription_request'}" class="ps-btn btn-sm"><i class="icon-upload"></i> Upload Prescription</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:['name', 'isPharmacy']
}
</script>