import axios from 'axios'

export default {

  data() {
    return {
      loading: true,
      isLoggedin: false,
      authData: localStorage.getItem("authData") ?
      JSON.parse(localStorage.getItem("authData")) : false,
      shopCategories: [],
      categories: [],
      sub_categories: [],
      sizes: [],
      colors: [],
      shop: {},
      cart_key: "bkasjbdfkjasdkfjhaksdfjskd",
      cart_contents: JSON.parse(localStorage.getItem("bkasjbdfkjasdkfjhaksdfjskd")),
      products: [],
      shop_category: {},
    };
  },
  computed: {
    image_url() {
      return process.env.VUE_APP_API_URL;
    }
  },
  methods: {
    logout() {
      // check storage if token is available
      if (localStorage.getItem('authData') == null) {
        this.isLoggedin = false;
      } else {
        localStorage.removeItem('authData');
        location.reload();
      }
    },
    checkIfLoggedIn() {
      if (localStorage.getItem('token') == null) {
        this.isLoggedin = false;
      } else {
        this.isLoggedin = true
      }
    },

    requestAuthHeader() {
      return {
        headers: {
          Accept: "application/json",
          'Access-Control-Allow-Origin': '*',
          'Authorization': "Bearer " + this.authData.token
        }
      };
    },

    getShopCategories() {
      // axios.get( 'shops/categories').then(response => {
      //   this.shopCategories = response.data
      //   this.loading = false;
      // }).catch(error => {
      //   this.loading = false;
      // })
    },

    getCategories() {
      // axios.get('categories').then(response => {
      //   this.categories = response.data.data
      // }).catch(error => {
      // })
    },

    getSubCategories() {
      // axios.get('sub-categories').then(response => {
      //   this.sub_categories = response.data.data
      // }).catch(error => {
      // })
    },

    getColors() {
      axios.get('colors').then(response => {
        this.colors = response.data
      }).catch(error => {

      })
    },

    getSizes() {
      axios.get( 'sizes').then(response => {
        this.sizes = response.data
      }).catch(error => {
      })
    },

    getShopCategory(id) {
      // axios.get('shop_category' + id).then((response) => {
      //     this.shop_category = response.data.data;
      //   }).catch((error) => {
      //   })
    },

    timeSince(date) {

      var seconds = Math.floor((new Date() - date) / 1000);

      var interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " years";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " months";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " days";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " hours";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " minutes";
      }
      return Math.floor(seconds) + " seconds";
    },

    fomartDateApp(date) {
      var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      var newDate = new Date(date);

      return newDate.toLocaleDateString("en-US", options);
    },

    async sync() {
      let _cart = JSON.stringify(this.cart_contents);
      await localStorage.setItem(this.cart_key, _cart);
    },

    find(id) {
      // find an item in the cart by it's id
      let match = this.cart_contents.filter(item => {
        if (item.id === id)
          return true;
      });
      if (match && match[0])
        return match[0];
    },

    add(product, prop) {
      // add a new item to the cart
      // check that it is not in the cart already
      if (this.cart_contents) {
        if (this.find(product.id)) {
          this.increase(product.id, 1, prop);
        } else {
          let obj = {
            id: product.id,
            title: product.name,
            qty: prop.qty,
            itemPrice: product.price,
            imagePath: "",
            props: [
              {
                code: this.computeCode(prop.color, prop.size),
                color: prop.color,
                size: prop.size,
                qty: prop.qty
              }
            ]
          };
          this.cart_contents.push(obj);
          //update localStorage
          this.sync();
        }
      } else {
        this.cart_contents = [];
        let obj = {
          id: product.id,
          title: product.name,
          qty: prop.qty,
          itemPrice: product.price,
          props: [
            {
              code: this.computeCode(prop.color, prop.size),
              color: prop.color,
              size: prop.size,
              qty: prop.qty
            }
          ]
        };
        this.cart_contents.push(obj);
        //update localStorage
        this.sync();
      }
    },

    increase(id, qty = 1, prop) {
      // increase the quantity of an item in the cart
      this.cart_contents = this.cart_contents.map(item => {
        if (item.id === id) {
          item.props.map((property, index) => {
            let color = null;
            let size = null;
            if (prop) {
                if (prop.hasOwnProperty('color')) {
                  color = prop.color;
                }
                if (prop.hasOwnProperty('size')) {
                  size = prop.size;
                }
            }

            if (property.code === this.computeCode(color, size)) {
               item.props[index].qty = item.props[index].qty + qty;
            } else {
               item.props.push({ code: this.computeCode(color, size), color: color, size: size, qty: qty })
            }
          })
        }
        return item;
      });
      //update localStorage
      this.sync()
    },

    reduce(id, qty = 1) {
      //reduce the quantity of an item in the cart
      this.cart_contents = this.cart_contents.map(item => {
        if (item.id === id)
          item.qty = item.qty - qty;
        return item;
      });
      this.cart_contents.forEach(async item => {
        if (item.id === id && item.qty === 0) {
            this.remove(id);
        }
      });
      //update localStorage
      this.sync()
    },

    removeProductFromCart(id) {
      //remove an item entirely from CART.contents based on its id
      this.cart_contents = this.cart_contents.filter(item => {
        if (item.id !== id)
          return true;
      });
      //update localStorage
      this.sync()
      location.reload();
    },

    empty() {
      //empty whole cart
      this.cart_contents = [];
      //update localStorage
      this.sync()
    },

    sort(field = 'title') {
      //sort by field - title, price
      //return a sorted shallow copy of the CART.contents array
      let sorted = this.cart_contents.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        } else if (a[field] < a[field]) {
          return -1;
        } else {
          return 0;
        }
      });
      return sorted;
      //NO impact on localStorage
    },

    logContents(prefix) {
      console.log(prefix, this.cart_contents)
    },

    computeCode(color, size) {
      let newColor = '';
      let newSize = '';
      if (color) {
         newColor = color.toLowerCase();
      }
      if (size) {
        newSize = size.toLowerCase();
      }
      return `${newColor.split('').sort().join('')}${newSize.split('').sort().join('')}`;
    }

  },
};